import get from 'lodash/get';
import * as en from './en.json';

type Options = {
  t: Function,
}

const useLocale = (): Options => ({
  t: (key: string): string => get(en, key, key.toString()),
});

export default useLocale;

// @flow
import React from 'react';
import type { Node } from 'react';
import { user } from '../../dummies';

function UserBox(): Node {
  return (
    <div className="user-wrapper">
      <img className="user-profile" src={user.profilePicture} alt="profile" />
      <div className="user-credentials">
        <h4 className="user-name">{user.name}</h4>
        <span className="user-role">{user.role}</span>
      </div>
    </div>
  );
}

export default UserBox;

// @flow
import React, { useEffect, useState } from 'react';
import type { Node } from 'react';
import { NavLink, useSearchParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faSearch, faTimes } from '@fortawesome/free-solid-svg-icons';
import useLocale from '../../locale';
import { useLocationInfo } from '../../utils';
import Logo from '../../assets/icons/Logo';
import colors from '../../styles/_colors.scss';
import Paw from '../../assets/icons/Paw';

const mediaQuery = window.matchMedia('(max-width: 991px)');

function Navigation(): Node {
  const { t } = useLocale();
  const [searchParams, setSearchParams] = useSearchParams();
  const locationInfo = useLocationInfo();
  const [showMenu, setShowMenu] = useState(false);
  const [smallScreen, setSmallScreen] = useState(
    window.matchMedia('(max-width: 991px)').matches,
  );

  const navLinks = [
    { path: '/', label: t('navigation.home') },
    { path: '/listing', label: t('navigation.list_dog') },
    { path: '/help', label: t('navigation.help') },
    { path: '/account?login', label: t('navigation.login') },
    { path: '/account?signup', label: t('navigation.signup') },
  ];

  useEffect(() => {
    const handleResize = () => {
      setSmallScreen(mediaQuery.matches);
    };

    mediaQuery.addEventListener('change', handleResize);

    return () => mediaQuery.removeEventListener('change', handleResize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <nav className="navigation-wrapper">
        <div className="navigation">
          <NavLink to="/" className="logo">
            {smallScreen ? (
              <Paw color={colors.primary} />
            ) : (
              <Logo color={colors.primary} />
            )}
          </NavLink>
          {locationInfo.isSearch && (
            <div className="postcode-wrapper">
              <input
                className="postcode"
                placeholder={t('navigation.inputs.postcode')}
                value={searchParams.get('postcode')}
                onChange={(e) => setSearchParams({ postcode: e.target.value })}
              />
              <button><FontAwesomeIcon icon={faSearch} color={colors.primary} /></button>
            </div>
          )}
          {smallScreen || locationInfo.isSearch ? (
            <FontAwesomeIcon
              icon={faBars}
              className="menu-icon"
              onClick={() => setShowMenu(!showMenu)}
            />
          ) : (
            <div className="links-wrapper">
              {navLinks.map((link) => (
                <NavLink
                  key={link.path}
                  to={link.path}
                  className="nav-link"
                >
                  <span>{link.label}</span>
                </NavLink>
              ))}
            </div>
          )}
        </div>
        {locationInfo.isSearch && (<div className="filters" />)}
      </nav>
      {showMenu && (
        <div className="mobile-nav">
          <FontAwesomeIcon
            icon={faTimes}
            className="menu-icon"
            onClick={() => setShowMenu(!showMenu)}
          />
          {navLinks.map((link) => (
            <NavLink key={link.path} to={link.path} className="nav-link">
              <span>{link.label}</span>
            </NavLink>
          ))}
        </div>
      )}
    </>
  );
}

export default Navigation;

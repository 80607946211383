// @flow
import React from 'react';
import type { Node } from 'react';
import { times } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronCircleLeft, faChevronCircleRight } from '@fortawesome/free-solid-svg-icons';
import DogProfile from '../elements/list-items/DogProfile';
import BusinessProfile from '../elements/list-items/BusinessProfile';
import Sponsored from '../elements/list-items/Sponsored';
import useLocale from '../../locale';
import { dog, sponsore } from '../../dummies';

type Props ={
  width: number,
}

function List(props: Props): Node {
  const { t } = useLocale();
  const { width } = props;
  const margin = (width - 1340) / 2;
  const mapWidth = (width - 720) / 2 + 200;
  const space = mapWidth + margin + 60;
  const fixedMargin = margin <= 0 ? Math.abs(margin) : 0;

  return (
    <div
      className="dog-list-wrapper"
      style={{ width: width <= 900 ? '100%' : width - space - fixedMargin }}
    >
      <div className="header-wrapper">
        <span className="found-count">50+ Dogs in your area</span>
        <hr className="spliter" />
        <div className="announcement-wrapper">
          <span>Announcement goes here e.g there are 1000 highly rated dogs in your area</span>
        </div>
      </div>
      {times(5, (i) => (<DogProfile key={i} profile={dog} />))}
      <div className="business-wrapper">
        <div className="header">
          <span>{t('business.label')}</span>
          <div className="arrows">
            <button><FontAwesomeIcon icon={faChevronCircleLeft} /></button>
            <button><FontAwesomeIcon icon={faChevronCircleRight} /></button>
          </div>
        </div>
        <div className="business-list">
          {times(5, (i) => (<BusinessProfile key={i} profile={sponsore} />))}
        </div>
      </div>
      <Sponsored sponsore={sponsore} />
      {times(5, (i) => (<DogProfile key={i} profile={dog} />))}
    </div>
  );
}

export default List;

// @flow
import React from 'react';
import type { Node } from 'react';
import { filter, includes, map } from 'lodash';
import useLocale from '../../../locale';
import useList from '../../../utils/lists';
import PawIcon from '../../../assets/icons/Paw';
import type { Dog } from '../../../types';

type Props = {
  profile: Dog,
}

function DogProfile(props: Props): Node {
  const { t } = useLocale();
  const { filters, tags } = useList();
  const { profile } = props;
  const tagsFiltered: Object[] = filter(tags, (val) => includes(profile.tags, val.value));
  const filtersFiltered: Object[] = filter(filters, (val) => includes(profile.filtres, val.value));

  return (
    <div className="dog-profile">
      <img src={profile.pictures[0]} alt='profile' />
      <div className="content-wrapper">
        <div className="rating-wrapper">
          <PawIcon />
          <span className="rating">{profile.rating}</span>
          <span className="count">({profile.ratingCount})</span>
        </div>
        <div className="profile-wrapper">
          <h1>{profile.name}</h1>
          <h3>- {profile.breed}</h3>
        </div>
        <span className="distance">{t('profile.distance.less_then_mile')}</span>
        <div className="bio">
          {map(tagsFiltered, (val, key) => (
            <span key={val.value}>{val.label}{key !== tagsFiltered.length - 1 && ' - '}</span>
          ))}
        </div>
        <div className="tags-wrapper">
          {map(filtersFiltered, (val) => (
            <button key={val.value} className="tag">{val.label}</button>
          ))}
        </div>
      </div>
    </div>
  );
}

export default DogProfile;

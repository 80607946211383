// @flow
import React, { useEffect, useState } from 'react';
import type { Node } from 'react';
import ThumbFilters from '../blocks/ThumbFilters';
import List from '../blocks/List';
import Map from '../blocks/Map';

function Search(): Node {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = (e) => setWidth(e.target.innerWidth);

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <main className="search-wrapper">
      <Map width={width} />
      <ThumbFilters />
      <List width={width} />
    </main>
  );
}

export default Search;

// @flow
import React from 'react';
import type { Node } from 'react';

type Props = {
  className?: string,
  color?: string,
  height?: string,
  width?: string,
  stroke?: boolean,
};

const Paw = (props: Props): Node => {
  if (props.stroke) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 82.94 67.34"
        height={props.height}
        width={props.width}
        fill={props.color}
        className={props.className}
      >
        <path d="M10.19,41.14A10.19,10.19,0,1,1,20.37,31,10.2,10.2,0,0,1,10.19,41.14Zm0-17.88A7.69,7.69,0,1,0,17.87,31,7.7,7.7,0,0,0,10.19,23.26Z" />
        <path d="M72.75,41.14A10.19,10.19,0,1,1,82.94,31,10.2,10.2,0,0,1,72.75,41.14Zm0-17.88A7.69,7.69,0,1,0,80.44,31,7.69,7.69,0,0,0,72.75,23.26Z" />
        <path d="M28.3,24a12,12,0,1,1,12-12A12,12,0,0,1,28.3,24Zm0-21.48A9.49,9.49,0,1,0,37.79,12,9.5,9.5,0,0,0,28.3,2.5Z" />
        <path d="M54.72,24a12,12,0,1,1,12-12A12,12,0,0,1,54.72,24Zm0-21.48A9.49,9.49,0,1,0,64.21,12,9.5,9.5,0,0,0,54.72,2.5Z" />
        <path d="M30.71,67.34h-.15a13.94,13.94,0,0,1-10.71-5.27,14.73,14.73,0,0,1-3.61-9.67v-.68c0-.23-.2-6.86,6.05-12.59l.36-.32a76,76,0,0,1,11.71-8.19,14.37,14.37,0,0,1,14.22,0,76.5,76.5,0,0,1,11.71,8.19l.35.32c5.53,5.06,6.14,11,6,12.59h0c0,.12,0,.23,0,.35s0,.21,0,.33a14.76,14.76,0,0,1-3.62,9.68,13.94,13.94,0,0,1-10.7,5.26,12.4,12.4,0,0,1-6.64-1.62,21.34,21.34,0,0,1-3.11-2.21l-1.16-1-1.18,1a20.75,20.75,0,0,1-3.09,2.2A12.38,12.38,0,0,1,30.71,67.34ZM24.3,40.69,24,41c-5.36,4.91-5.25,10.46-5.24,10.69v.51a1.61,1.61,0,0,0,0,.22,12.25,12.25,0,0,0,3,8,11.56,11.56,0,0,0,8.83,4.4A9.9,9.9,0,0,0,36,63.54a19,19,0,0,0,2.7-1.93l2.79-2.3,2.77,2.29A18.79,18.79,0,0,0,47,63.54a10,10,0,0,0,5.4,1.3,11.53,11.53,0,0,0,8.83-4.4,12.26,12.26,0,0,0,3-8,1.55,1.55,0,0,0,0-.22V52a2.26,2.26,0,0,0,0-.26v-.25A15.38,15.38,0,0,0,59,41l-.32-.29a72.72,72.72,0,0,0-11.3-7.9,11.85,11.85,0,0,0-11.73,0A73.43,73.43,0,0,0,24.3,40.69Z" />
      </svg>
    );
  }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 80.44 64.84"
      height={props.height}
      width={props.width}
      fill={props.color}
      className={props.className}
    >
      <circle
        cx="8.94"
        cy="29.7"
        r="8.94"
        transform="translate(-6.58 2.85) rotate(-13.28)"
      />
      <circle
        cx="71.5"
        cy="29.7"
        r="8.94"
        transform="translate(-4.91 17.22) rotate(-13.28)"
      />
      <circle cx="27.05" cy="10.74" r="10.74" />
      <circle cx="53.47" cy="10.74" r="10.74" />
      <path d="M22.22,38.5l-.33.3c-5.9,5.41-5.65,11.67-5.65,11.67s0,.23,0,.35,0,.21,0,.33A13.43,13.43,0,0,0,19.55,60a12.8,12.8,0,0,0,9.77,4.83,11.3,11.3,0,0,0,6-1.46,19.82,19.82,0,0,0,2.9-2.07l2-1.63,2,1.63a19.92,19.92,0,0,0,2.91,2.07,11.3,11.3,0,0,0,6,1.46A12.82,12.82,0,0,0,60.89,60a13.48,13.48,0,0,0,3.31-8.86c0-.12,0-.23,0-.33s0-.23,0-.35.25-6.26-5.65-11.67l-.34-.3a74,74,0,0,0-11.51-8,13.1,13.1,0,0,0-13,0A75.1,75.1,0,0,0,22.22,38.5Z" />
    </svg>
  );
};

export default Paw;

// @flow
import profileImg from './images/profile.jpg';
import dogImg from '../assets/images/search-image.jpeg';
import sponsoreImg from '../assets/images/local-image.jpeg';
import type { Dog, Business } from '../types';

export const user = {
  name: 'Jane Doe',
  role: 'Dog Sitter',
  profilePicture: profileImg,
};

export const dog: Dog = {
  name: 'Dog name',
  breed: 'Breed',
  pictures: [dogImg],
  distance: {
    lat: 0,
    lon: 0,
  },
  tags: ['current', 'friendly', 'adult', 'calm'],
  filtres: ['walkers', 'sitters'],
  rating: 4.50,
  ratingCount: 20,
};

export const sponsore: Business = {
  name: 'Business Name',
  category: 'Category',
  img: sponsoreImg,
  distance: {
    lat: 0,
    lon: 0,
  },
  businessTags: ['caffe', 'affordable', 'vegan'],
  rating: 4.50,
  ratingCount: 20,
};

// @flow
import React, { useState } from 'react';
import type { Node } from 'react';
import { Link } from 'react-router-dom';
import { isEmpty } from 'lodash';
import classNames from 'classnames';
import ReviewCard from '../elements/ReviewCard';
import ValuesCard from '../elements/ValuesCard';
import PawIcon from '../../assets/icons/Paw';
import HomeIcon from '../../assets/icons/Home';
import HeartIcon from '../../assets/icons/Heart';
import useLocale from '../../locale';

function Home(): Node {
  const { t } = useLocale();
  const [postcode, setPostcode] = useState('');
  const [newsletterSubscribe, setNewsletterSubscribe] = useState(false);
  const linkURL = `/search?postcode=${postcode.replace(/\s/g, '')}`;

  const images = {
    search: require('../../assets/images/search-image.jpeg'),
    local: require('../../assets/images/local-image.jpeg'),
    premium: require('../../assets/images/premium-image.jpeg'),
    newsletter: require('../../assets/images/newsletter-image.jpeg'),
  };

  const valuesCards = [
    {
      icon: <PawIcon className="value-icon" stroke />,
      title: t('home.values.cards.card_1.title'),
      paragraph: t('home.values.cards.card_1.paragraph'),
    },
    {
      icon: <HomeIcon className="value-icon" stroke />,
      title: t('home.values.cards.card_2.title'),
      paragraph: t('home.values.cards.card_2.paragraph'),
    },
    {
      icon: <HeartIcon className="value-icon" stroke />,
      title: t('home.values.cards.card_3.title'),
      paragraph: t('home.values.cards.card_3.paragraph'),
    },
  ];

  const handleLink = (e) => {
    if (isEmpty(postcode)) e.preventDefault();
  };

  return (
    <main className="home-wrapper">
      <div className="search-wrapper bg-image-section">
        <img src={images.search} alt="search-bg" className="sized left" />
        <div className="form-wrapper left">
          <h1>
            {t('home.search.form.title_1')}
            <span>{t('home.search.form.title_2')}</span>
            {t('home.search.form.title_3')}
          </h1>
          <span className="form-subtitle">
            {t('home.search.form.subtitle')}
          </span>
          <hr />
          <span className="form-question">
            {t('home.search.form.input_postcode.question')}
          </span>
          <input
            className="input-text"
            placeholder={t('home.search.form.input_postcode.placeholder')}
            value={postcode}
            onChange={(e) => setPostcode(e.target.value)}
          />
          {/* <span className="form-question">
            {t("home.search.form.input_filter.question")}
          </span>
          <div className="form-filter-wrapper">
            {filters.map((filter) => (
              <button
                key={filter.value}
                className={classNames("filter-tag", {
                  active: some(filterBy, filter),
                })}
                onClick={() => handleFilterClick(filter)}
              >
                {filter.label}
              </button>
            ))}
          </div> */}
          <Link
            to={linkURL}
            onClick={(e) => handleLink(e)}
            className={classNames('button-round', {
              disabled: isEmpty(postcode),
            })}
          >
            {t('home.search.form.cta')}
          </Link>
        </div>
      </div>
      <div className="local-wrapper bg-image-section">
        <img src={images.local} alt="local-bg" />
        <div className="content-wrapper right">
          <h1>{t('home.local.title')}</h1>
          <span>{t('home.local.paragraph')}</span>
          <Link to="/local-business/signup" className="button-round">
            {t('home.local.cta')}
          </Link>
        </div>
      </div>
      <div className="review-wrapper">
        <h1 className="section-title">{t('home.review.title')}</h1>
        <div className="scroll-wrapper">
          <div className="card-wrapper">
            {[...Array(3).keys()].map((key) => (
              <ReviewCard
                key={key}
                data={{
                  rating: 4.5,
                  comment:
                    'I loved spending time with Dennis, he really helped me relax and reduce the stress I was feeling, it was so rewarding!',
                }}
              />
            ))}
          </div>
        </div>
      </div>
      <div className="values-wrapper">
        <h1 className="section-title">{t('home.values.title')}</h1>
        <div className="card-wrapper">
          {valuesCards.map((value) => (
            <ValuesCard key={value.title} data={value} />
          ))}
        </div>
      </div>
      <div className="premium-wrapper bg-image-section">
        <img src={images.premium} alt="premium-bg" />
        <div className="content-wrapper left">
          <h1 className="single-line">{t('home.premium.title')}</h1>
          <span>{t('home.premium.paragraph')}</span>
          <Link to="/premium-membership/signup" className="button-round">
            {t('home.premium.cta')}
          </Link>
        </div>
      </div>
      <div className="newsletter-wrapper bg-image-section">
        <img
          src={images.newsletter}
          alt="newsletter-bg"
          className="sized left"
        />
        <div className="form-wrapper left">
          <h1>{t('home.newsletter.form.title')}</h1>
          <span className="form-subtitle">
            {t('home.newsletter.form.subtitle')}
          </span>
          <hr />
          <span className="form-question">
            {t('home.newsletter.form.input_email.question')}
          </span>
          <input
            className="input-text"
            placeholder={t('home.newsletter.form.input_email.placeholder')}
            value={postcode}
            onChange={(e) => setPostcode(e.target.value)}
          />
          <label className="input-checkbox">
            {t('home.newsletter.form.input_additional.placeholder')}
            <input
              type="checkbox"
              checked={newsletterSubscribe}
              onChange={() => setNewsletterSubscribe(!newsletterSubscribe)}
            />
          </label>
          <Link to="/" className="button-round">
            {t('home.newsletter.form.cta')}
          </Link>
        </div>
      </div>
    </main>
  );
}

export default Home;

// @flow
import React from 'react';
import type { Node } from 'react';
import { filter, includes, map } from 'lodash';
import useLocale from '../../../locale';
import useList from '../../../utils/lists';
import PawIcon from '../../../assets/icons/Paw';
import type { Business } from '../../../types';

type Props = {
  sponsore: Business
}

function Sponsored(props: Props): Node {
  const { t } = useLocale();
  const { businessTags } = useList();
  const { sponsore } = props;
  const tagsFiltered: Object[] = filter(businessTags, (val) => includes(sponsore.businessTags, val.value));

  return (
    <div className="sponsored-wrapper">
      <div
        className="overlay-wrapper"
        style={{
          backgroundImage: `url(${sponsore.img})`,
        }}
      >
        <span className="spon-tag">{t('sponsored.tag')}</span>
        <div className="content-wrapper">
          <div className="rating-wrapper">
            <PawIcon />
            <span className="rating">{sponsore.rating}</span>
            <span className="count">({sponsore.ratingCount})</span>
          </div>
          <div className="profile-wrapper">
            <h1>{sponsore.name}</h1>
            <h3>- {sponsore.category}</h3>
          </div>
          <span className="distance">{t('profile.distance.less_then_mile')}</span>
          <div className="tags-wrapper">
            {map(tagsFiltered, (val) => (
              <button key={val.value} className="tag">{val.label}</button>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Sponsored;

// @flow
import React, { useState } from 'react';
import type { Node } from 'react';
import classNames from 'classnames';
import useList from '../../utils/lists';

function ThumbFilters(): Node {
  const { filters } = useList();
  const [filterBy, setFilterBy] = useState(null);

  return (
    <div className="thumb-filters-wrapper">
      <div className="thumb-filters">
        {filters.map((filter) => (
          <div
            key={filter.value}
            className={classNames('thumb', {
              active: filterBy && filterBy.value === filter.value,
            })}
            onClick={() => setFilterBy(filter)}
          >
            <img src={filter.thumb} alt={filter.label} />
            <span>{filter.label}</span>
          </div>
        ))}
      </div>
    </div>
  );
}

export default ThumbFilters;

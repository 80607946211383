// @flow
import React, { useEffect, useState } from 'react';
import type { Node } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleUp } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';
import { useLocationInfo } from '../../utils';

function ScrollToTop(): Node {
  const locationInfo = useLocationInfo();
  const [show, setShow] = useState(false);

  const toTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollOffset = window.pageYOffset >= window.innerHeight * 0.8;
      if (scrollOffset) setShow(true);
      else setShow(false);
    };

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', () => handleScroll);
  }, []);

  return (
    <div
      id="to-top"
      className={classNames({
        active: show,
        left: locationInfo.isSearch,
      })}
      onClick={toTop}
    >
      <FontAwesomeIcon icon={faAngleUp} />
    </div>
  );
}

export default ScrollToTop;

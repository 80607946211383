// @flow
import React from 'react';
import type { Node } from 'react';
import { isEmpty } from 'lodash';
import UserBox from './UserBox';

type Card = {
  rating: number,
  comment: string,
};

type Props = {
  data: Card,
};

function ReviewCard(props: Props): Node {
  const { data } = props;

  if (isEmpty(data)) return null;

  return (
    <div className="review-card-wrapper">
      <img
        className="dog-profile"
        src={require('../../assets/images/search-image.jpeg')}
        alt="dog"
      />
      <div className="review-wraper">
        <div className="user-box">
          <UserBox />
          <span className="rating">{data.rating}</span>
        </div>
        <span className="review-comment">
          &quot;{data.comment.substring(0, 115)}...&quot;
        </span>
      </div>
    </div>
  );
}

export default ReviewCard;

// @flow
import useLocale from '../locale';
import dummyImg from '../assets/images/search-image.jpeg';

type Lists = {
  filters: Array<any>,
  tags: Array<any>,
  businessTags: Array<any>,
};

const useList = (): Lists => {
  const { t } = useLocale();

  const filters = [
    { label: t('filters.dogs'), value: 'dogs', thumb: dummyImg },
    { label: t('filters.walkers'), value: 'walkers', thumb: dummyImg },
    { label: t('filters.sitters'), value: 'sitters', thumb: dummyImg },
    { label: t('filters.kennels'), value: 'kennels', thumb: dummyImg },
    { label: t('filters.shops'), value: 'shops', thumb: dummyImg },
    { label: t('filters.trainers'), value: 'trainers', thumb: dummyImg },
    { label: t('filters.business'), value: 'business', thumb: dummyImg },
  ];

  const tags = [
    { label: t('tags.current'), value: 'current' },
    { label: t('tags.friendly'), value: 'friendly' },
    { label: t('tags.adult'), value: 'adult' },
    { label: t('tags.calm'), value: 'calm' },
  ];

  const businessTags = [
    { label: t('businessTags.caffe'), value: 'caffe' },
    { label: t('businessTags.affordable'), value: 'affordable' },
    { label: t('businessTags.vegan'), value: 'vegan' },
  ];

  return {
    filters,
    tags,
    businessTags,
  };
};

export default useList;

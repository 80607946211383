// @flow
import React from 'react';
import type { Node } from 'react';

type Props = {
  className?: string,
  color?: string,
  height?: string,
  width?: string,
};

const Logo = (props: Props): Node => (
  <svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 787 74.38"
    height={props.height}
    width={props.width}
    fill={props.color}
    className={props.className}
  >
    <circle cx="9.71" cy="35.29" r="9.71" />
    <circle cx="77.68" cy="35.3" r="9.71" />
    <circle cx="29.38" cy="14.7" r="11.67" />
    <circle cx="58.09" cy="14.7" r="11.67" />
    <path
      d="M63.62,45.19l-.37-.33a81.49,81.49,0,0,0-12.5-8.75,14.24,14.24,0,0,0-14.1,0,81.49,81.49,0,0,0-12.5,8.75l-.36.33c-6.42,5.87-6.15,12.67-6.15,12.67v.74a14.58,14.58,0,0,0,3.6,9.63,13.93,13.93,0,0,0,10.62,5.24,12.16,12.16,0,0,0,6.54-1.58,21.3,21.3,0,0,0,3.16-2.25l2.14-1.77,2.14,1.77A21.3,21.3,0,0,0,49,71.89a12.19,12.19,0,0,0,6.55,1.58,13.91,13.91,0,0,0,10.61-5.24,14.58,14.58,0,0,0,3.6-9.63v-.74C69.76,57.73,70,51.06,63.62,45.19Z"
      transform="translate(-0.01)"
    />
    <path
      d="M142,64.72h19.85A1.25,1.25,0,0,1,163.08,66h0v6.25a1.25,1.25,0,0,1-1.25,1.25H131.12a1.25,1.25,0,0,1-1.26-1.24h0V8.57a1.25,1.25,0,0,1,1.24-1.26h8.35a1.25,1.25,0,0,1,1.26,1.24V63.46A1.25,1.25,0,0,0,142,64.72Z"
      transform="translate(-0.01)"
    />
    <path
      d="M181.45,71A24.16,24.16,0,0,1,172,61.43a28.57,28.57,0,0,1-3.4-14.23A28.18,28.18,0,0,1,172.12,33a24.37,24.37,0,0,1,9.62-9.51,29.42,29.42,0,0,1,27.22,0A24.31,24.31,0,0,1,218.58,33a28.18,28.18,0,0,1,3.52,14.2,27.52,27.52,0,0,1-3.61,14.18A25.06,25.06,0,0,1,208.63,71a28.28,28.28,0,0,1-13.75,3.38A26.81,26.81,0,0,1,181.45,71Zm21.28-8a15.45,15.45,0,0,0,6-6A19.33,19.33,0,0,0,211,47.2a19.55,19.55,0,0,0-2.19-9.66,14.76,14.76,0,0,0-5.8-5.95,15.82,15.82,0,0,0-7.81-2,15.61,15.61,0,0,0-7.76,2,14.18,14.18,0,0,0-5.66,5.95,20.46,20.46,0,0,0-2.1,9.66q0,8.47,4.34,13.09a14.3,14.3,0,0,0,10.9,4.62,16.15,16.15,0,0,0,7.81-2Z"
      transform="translate(-0.01)"
    />
    <path
      d="M232.53,33a23.35,23.35,0,0,1,9.09-9.47A26.22,26.22,0,0,1,255,20.16q9.51,0,15.75,4.52a21.83,21.83,0,0,1,7.94,11.23,1.26,1.26,0,0,1-1.2,1.67h-9.18a1.28,1.28,0,0,1-1.16-.78,12.15,12.15,0,0,0-4.25-5.31A13.47,13.47,0,0,0,255,29.3,13.17,13.17,0,0,0,244.33,34q-3.95,4.71-4,13.19t4,13.23A13.06,13.06,0,0,0,255,65.19q9,0,12.15-7.49a1.28,1.28,0,0,1,1.16-.79h9.14a1.25,1.25,0,0,1,1.26,1.26,1.16,1.16,0,0,1-.08.45,23.18,23.18,0,0,1-8,11q-6.28,4.71-15.61,4.71A25.93,25.93,0,0,1,241.62,71a23.66,23.66,0,0,1-9.09-9.52,29.82,29.82,0,0,1-3.29-14.23A29.64,29.64,0,0,1,232.53,33Z"
      transform="translate(-0.01)"
    />
    <path
      d="M289.65,33a23.91,23.91,0,0,1,21.46-12.85,22.89,22.89,0,0,1,10.81,2.43,23.65,23.65,0,0,1,5.27,3.71,1.26,1.26,0,0,0,1.77-.05,1.24,1.24,0,0,0,.34-.86v-3.1A1.25,1.25,0,0,1,330.52,21H339l1.27,1.26V72.21L339,73.47h-8.42l-1.26-1.26V69a1.25,1.25,0,0,0-2.11-.91,23.5,23.5,0,0,1-5.41,3.81,24.38,24.38,0,0,1-23.23-1,24.69,24.69,0,0,1-8.9-9.71A29.89,29.89,0,0,1,286.36,47,29.13,29.13,0,0,1,289.65,33Zm37.41,4.76a15.78,15.78,0,0,0-5.85-6.09,15.65,15.65,0,0,0-21.47,5.91A18.75,18.75,0,0,0,297.5,47a19.58,19.58,0,0,0,2.24,9.52,15.72,15.72,0,0,0,5.9,6.23,15.14,15.14,0,0,0,7.76,2.15,15.33,15.33,0,0,0,7.81-2.1,15.56,15.56,0,0,0,5.85-6.14,19.15,19.15,0,0,0,2.24-9.47,19,19,0,0,0-2.24-9.42Z"
      transform="translate(-0.01)"
    />
    <path
      d="M365.38,4.28V72.22a1.27,1.27,0,0,1-1.26,1.25h-8.34a1.27,1.27,0,0,1-1.26-1.25V4.28A1.25,1.25,0,0,1,355.76,3h8.36a1.25,1.25,0,0,1,1.26,1.26Z"
      transform="translate(-0.01)"
    />
    <path
      d="M490.09,8.93,471.22,72.57a1.25,1.25,0,0,1-1.21.9H459.63a1.25,1.25,0,0,1-1.22-.92L446,27.64a1.26,1.26,0,0,0-2.42,0l-13.25,45a1.25,1.25,0,0,1-1.2.9l-10.29.08a1.25,1.25,0,0,1-1.22-.91L399.62,9a1.25,1.25,0,0,1,.87-1.55,1.33,1.33,0,0,1,.34,0h8.88a1.27,1.27,0,0,1,1.22,1L423.22,56.7a1.26,1.26,0,0,0,2.44,0L438.9,8.24a1.25,1.25,0,0,1,1.21-.93h10.26a1.25,1.25,0,0,1,1.21,1l12.35,47.92a1.25,1.25,0,0,0,2.43,0l12.35-48a1.25,1.25,0,0,1,1.22-1h8.95a1.27,1.27,0,0,1,1.27,1.27A1.11,1.11,0,0,1,490.09,8.93Z"
      transform="translate(-0.01)"
    />
    <path
      d="M508.84,71a24.18,24.18,0,0,1-9.43-9.52A28.77,28.77,0,0,1,496,47.2,28.17,28.17,0,0,1,499.51,33a24.31,24.31,0,0,1,9.62-9.51,29.42,29.42,0,0,1,27.22,0A24.32,24.32,0,0,1,546,33a28.14,28.14,0,0,1,3.52,14.19,27.4,27.4,0,0,1-3.62,14.18A25,25,0,0,1,536,71a28.24,28.24,0,0,1-13.76,3.38A26.81,26.81,0,0,1,508.84,71Zm21.28-8a15.45,15.45,0,0,0,6-6,19.32,19.32,0,0,0,2.28-9.71,19.66,19.66,0,0,0-2.19-9.66,14.66,14.66,0,0,0-5.81-6,16.13,16.13,0,0,0-15.56,0,14.21,14.21,0,0,0-5.67,6A20.5,20.5,0,0,0,507,47.2q0,8.47,4.33,13.09a14.3,14.3,0,0,0,10.9,4.62,16.12,16.12,0,0,0,7.89-2Z"
      transform="translate(-0.01)"
    />
    <path
      d="M569.58,71a24.18,24.18,0,0,1-9.43-9.52,28.81,28.81,0,0,1-3.43-14.23A28.25,28.25,0,0,1,560.25,33a24.28,24.28,0,0,1,9.61-9.51,29.44,29.44,0,0,1,27.23,0A24.37,24.37,0,0,1,606.71,33a28.25,28.25,0,0,1,3.52,14.19,27.6,27.6,0,0,1-3.62,14.18A25,25,0,0,1,596.76,71,28.33,28.33,0,0,1,583,74.33,26.76,26.76,0,0,1,569.58,71Zm21.27-8a15.26,15.26,0,0,0,5.95-6,19.32,19.32,0,0,0,2.29-9.71,19.66,19.66,0,0,0-2.19-9.66,14.72,14.72,0,0,0-5.81-6,15.82,15.82,0,0,0-7.81-2,15.57,15.57,0,0,0-7.75,2,14.21,14.21,0,0,0-5.67,6,20.33,20.33,0,0,0-2.09,9.66q0,8.48,4.33,13.09A14.29,14.29,0,0,0,583,64.91a16.19,16.19,0,0,0,7.85-2Z"
      transform="translate(-0.01)"
    />
    <path
      d="M641.72,29.87h-7.2a1.28,1.28,0,0,0-1.26,1.26V72.22A1.25,1.25,0,0,1,632,73.47h-8.45a1.25,1.25,0,0,1-1.25-1.25V31.13a1.28,1.28,0,0,0-1.26-1.26h-3.68a1.25,1.25,0,0,1-1.25-1.25h0V22.28A1.25,1.25,0,0,1,617.34,21h3.72a1.25,1.25,0,0,0,1.26-1.26h0V17.31q0-9,4.8-13.19Q631.62.25,640.92,0c.51.49.79.77,1.29,1.27V7.8A1.24,1.24,0,0,1,641,9.05c-2.67.14-4.59.75-5.78,1.83q-2,1.86-2,6.43v2.45A1.25,1.25,0,0,0,634.5,21h7.22A1.25,1.25,0,0,1,643,22.22v6.4a1.25,1.25,0,0,1-1.25,1.25Z"
      transform="translate(-0.01)"
    />
    <path
      d="M699.18,51.29H661.62l-1.25,1.49a14.14,14.14,0,0,0,4.5,8.6,14.71,14.71,0,0,0,10.28,3.81q8.34,0,12-6.66a1.25,1.25,0,0,1,1.1-.67h9.13a1.27,1.27,0,0,1,1.27,1.27,1.24,1.24,0,0,1-.1.5,23.54,23.54,0,0,1-8,10.08q-6.24,4.62-15.47,4.62A26.71,26.71,0,0,1,661.68,71a24,24,0,0,1-9.33-9.52A29,29,0,0,1,649,47.2,29.79,29.79,0,0,1,652.25,33a23,23,0,0,1,9.24-9.47,27.47,27.47,0,0,1,13.66-3.34,26.67,26.67,0,0,1,13.23,3.24,22.82,22.82,0,0,1,9,9.09A27.44,27.44,0,0,1,700.66,46a35.33,35.33,0,0,1-.23,4.24C700,50.62,699.66,50.88,699.18,51.29ZM688,42.53a1.27,1.27,0,0,0,1.27-1.27.76.76,0,0,0,0-.15,12.12,12.12,0,0,0-4.2-8.19,15.28,15.28,0,0,0-10.37-3.62,13.94,13.94,0,0,0-9.62,3.57A14.48,14.48,0,0,0,660.55,41a1.27,1.27,0,0,0,1,1.51,1.15,1.15,0,0,0,.26,0Z"
      transform="translate(-0.01)"
    />
    <path
      d="M728.51,22.4a18,18,0,0,1,8.08-2.2l1.3,1.26v8.68a1.28,1.28,0,0,1-1.26,1.26h-1.5q-6.39,0-9.67,3.23t-3.28,11.24V72.22a1.25,1.25,0,0,1-1.25,1.25h-8.35a1.25,1.25,0,0,1-1.25-1.25V22.28A1.25,1.25,0,0,1,712.55,21h8.37a1.25,1.25,0,0,1,1.26,1.24v2.58a1.26,1.26,0,0,0,2.2.83A16.66,16.66,0,0,1,728.51,22.4Z"
      transform="translate(-0.01)"
    />
    <path
      d="M755.83,72.09A19.46,19.46,0,0,1,748.07,66a15.19,15.19,0,0,1-2.92-7.17,1.27,1.27,0,0,1,1.09-1.43h8.94l1.22,1A7.81,7.81,0,0,0,759.45,63a11.59,11.59,0,0,0,7.28,2.23,12.31,12.31,0,0,0,7.09-1.76A5.31,5.31,0,0,0,776.35,59a4.66,4.66,0,0,0-2.81-4.38,53.15,53.15,0,0,0-8.9-3.14A86.27,86.27,0,0,1,755,48.25a16.91,16.91,0,0,1-6.42-4.67,12.19,12.19,0,0,1-2.72-8.28,12.93,12.93,0,0,1,2.48-7.66,16.49,16.49,0,0,1,7.09-5.48,26.5,26.5,0,0,1,10.62-2q8.94,0,14.42,4.53a15.88,15.88,0,0,1,5.74,10.89A1.26,1.26,0,0,1,785.1,37h-8.49a1.25,1.25,0,0,1-1.23-1,7.51,7.51,0,0,0-2.73-4.62,10.65,10.65,0,0,0-7-2.1,11.29,11.29,0,0,0-6.56,1.62,5,5,0,0,0-2.22,3.4,5.55,5.55,0,0,0,2.53,5.28A11.22,11.22,0,0,0,762.07,41c1.46.55,3.61,1.23,6.47,2a73.52,73.52,0,0,1,9.38,3.09,17.54,17.54,0,0,1,6.33,4.62,12.23,12.23,0,0,1,2.75,8.1,13.56,13.56,0,0,1-2.48,8,16.42,16.42,0,0,1-7,5.52,28.82,28.82,0,0,1-21.69-.24Z"
      transform="translate(-0.01)"
    />
  </svg>
);

export default Logo;

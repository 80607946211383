// @flow
import React from 'react';
import type { Node } from 'react';

type Props = {
  color?: string,
  height?: number | string,
  stroke?: boolean,
  className?: string,
  width?: number | string,
};

const Home = (props: Props): Node => {
  if (props.stroke) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 63.84 61.01"
        height={props.height}
        width={props.width}
        className={props.className}
        fill={props.color}
      >
        <path d="M49.18,61H43A5.06,5.06,0,0,1,38,56V39.75a6.07,6.07,0,0,0-12.13,0V56a5.05,5.05,0,0,1-5,5H14.65A14.67,14.67,0,0,1,0,46.36V29.06A19,19,0,0,1,8.18,13.44L23.88,2.5A14.17,14.17,0,0,1,40,2.5L55.66,13.44a19,19,0,0,1,8.18,15.62v17.3A14.68,14.68,0,0,1,49.18,61ZM31.92,31.19a8.57,8.57,0,0,1,8.56,8.56V56A2.55,2.55,0,0,0,43,58.51h6.15A12.17,12.17,0,0,0,61.34,46.36V29.06a16.5,16.5,0,0,0-7.11-13.57L38.52,4.55a11.64,11.64,0,0,0-13.21,0L9.6,15.49A16.49,16.49,0,0,0,2.5,29.06v17.3A12.16,12.16,0,0,0,14.65,58.51h6.16A2.55,2.55,0,0,0,23.35,56V39.75A8.58,8.58,0,0,1,31.92,31.19Z" />
      </svg>
    );
  }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 61.34 58.52"
      height={props.height}
      width={props.width}
      className={props.className}
      fill={props.color}
    >
      <path d="M61.34,27.83V45.12a13.4,13.4,0,0,1-13.41,13.4H41.78A3.79,3.79,0,0,1,38,54.73V38.52a7.31,7.31,0,0,0-7.31-7.31h0a7.31,7.31,0,0,0-7.32,7.31V54.73a3.79,3.79,0,0,1-3.79,3.79H13.4A13.4,13.4,0,0,1,0,45.12V27.83a17.75,17.75,0,0,1,7.64-14.6L23.35,2.29A12.86,12.86,0,0,1,38,2.29L53.7,13.23A17.78,17.78,0,0,1,61.34,27.83Z" />
    </svg>
  );
};

export default Home;

// @flow
import React from 'react';
import type { Node } from 'react';
import { useLocationInfo } from '../../utils';

function Footer(): Node {
  const locationInfo = useLocationInfo();

  if (locationInfo.isSearch) return null;

  return (
    <footer id="footer">
      <span>
        Local Woofers | Copyright &copy; {new Date().getFullYear()} | All rights
        reserved
      </span>
    </footer>
  );
}

export default Footer;

// @flow
import React from 'react';
import type { Node } from 'react';
import { isEmpty } from 'lodash';

type Card = {
  icon: Node,
  title: string,
  paragraph: string,
};

type Props = {
  data: Card,
};

function ValuesCard(props: Props): Node {
  const { data } = props;

  if (isEmpty(data)) return null;

  return (
    <div className="values-card-wrapper">
      {data.icon}
      <h1 className="values-title">{data.title}</h1>
      <hr />
      <span className="values-paragraph">{data.paragraph}</span>
    </div>
  );
}

export default ValuesCard;

// @flow
import React, { useEffect, useState } from 'react';
import type { Node } from 'react';

const mediaQuery = window.matchMedia('(max-width: 991px)');

function Bottom(): Node {
  const [smallScreen, setSmallScreen] = useState(
    window.matchMedia('(max-width: 900px)').matches,
  );

  useEffect(() => {
    const handleResize = () => {
      setSmallScreen(mediaQuery.matches);
    };

    mediaQuery.addEventListener('change', handleResize);

    return () => mediaQuery.removeEventListener('change', handleResize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!smallScreen) return null;

  return (
    <div id="bottom" />
  );
}

export default Bottom;

// @flow
import React from 'react';
import { Link } from 'react-router-dom';
import type { Node } from 'react';
import useLocale from '../../locale';
import Logo from '../../assets/icons/Logo';
import colors from '../../styles/_colors.scss';

type Props = {
  code?: string,
  title: string,
  message: string,
  error?: string,
};

function Error(props: Props): Node {
  const { t } = useLocale();

  return (
    <div className="error-wrapper">
      <Logo color={colors.white} />
      {props.code && <h1>{props.code}</h1>}
      <h3>{props.title}</h3>
      <span>{props.message}</span>
      {props.error && <span>{props.error}</span>}
      <Link to="/">
        <span>{t('error.cta')}</span>
      </Link>
    </div>
  );
}

export default Error;

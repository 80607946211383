// @flow
// import React { useEffect, useRef } from "react";
import React from 'react';
import type { Node } from 'react';
// import { Wrapper, Status } from "@googlemaps/react-wrapper";

// type Props = {
//   center: { lat: number, lng: number };
//   zoom: number;
// }

// function MapChild(props: Props): Node {
//   const mapRef = useRef();

//   useEffect(() => {
//     // eslint-disable-next-line no-new
//     new window.google.maps.Map(mapRef.current, {
//       center: props.center,
//       zoom: props.zoom,
//     });
//   });

//   return <div ref={mapRef} className="map" />;
// }

type Props ={
  width: number,
}

function Map(props: Props): Node {
  // const center = { lat: -34.397, lng: 150.644 };
  // const zoom = 4;

  // const renderMap = (status) => {
  //   switch (status) {
  //     case Status.LOADING:
  //       return "Loading";
  //     case Status.FAILURE:
  //       return "Error";
  //     case Status.SUCCESS:
  //       return <MapChild center={center} zoom={zoom} />;
  //     default:
  //       return null;
  //   }
  // };

  // return <Wrapper apiKey="YOUR_API_KEY" render={renderMap} />;
  return (
    <iframe
      style={{
        width: props.width <= 900 ? '100vw' : (props.width - 720) / 2 + 200,
      }}
      title="map"
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d39932.28547408597!2d1.0488314734886026!3d51.2785923677383!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47d94ac50d832c89%3A0x9ef1e5533783f98a!2sCanterbury%2C%20UK!5e0!3m2!1sen!2shr!4v1648938425585!5m2!1sen!2shr"
      className="map"
      allowFullScreen=""
      loading="lazy"
      referrerPolicy="no-referrer-when-downgrade"
    />
  );
}

export default Map;

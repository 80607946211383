// @flow
import React from 'react';
import type { Node } from 'react';
import { filter, includes, map } from 'lodash';
import useLocale from '../../../locale';
import useList from '../../../utils/lists';
import PawIcon from '../../../assets/icons/Paw';
import type { Business } from '../../../types';

type Props = {
  profile: Business,
}

function BusinessProfile(props: Props): Node {
  const { t } = useLocale();
  const { businessTags } = useList();
  const { profile } = props;
  const tagsFiltered: Object[] = filter(businessTags, (val) => includes(profile.businessTags, val.value));

  return (
    <div className="business-profile">
      <img src={profile.img} alt='profile' />
      <div className="content-wrapper">
        <div className="rating-wrapper">
          <PawIcon />
          <span className="rating">{profile.rating}</span>
          <span className="count">({profile.ratingCount})</span>
        </div>
        <div className="profile-wrapper">
          <h1>{profile.name}</h1>
          <h3>- {profile.category}</h3>
        </div>
        <span className="distance">{t('profile.distance.less_then_mile')}</span>
        <div className="tags-wrapper">
          {map(tagsFiltered, (val) => (
            <button key={val.value} className="tag">{val.label}</button>
          ))}
        </div>
      </div>
    </div>
  );
}

export default BusinessProfile;

// @flow
import React from 'react';
import type { Node } from 'react';

type Props = {
  color?: string,
  height?: number | string,
  stroke?: boolean,
  className?: string,
  width?: number | string,
};

const Heart = (props: Props): Node => {
  if (props.stroke) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 62.12 47.38"
        height={props.height}
        width={props.width}
        className={props.className}
        fill={props.color}
      >
        <path d="M31.06,47.38a17.51,17.51,0,0,1-8.68-2.3A94.12,94.12,0,0,1,7.87,34.93l-.44-.4C-.24,27.51,0,19.41,0,19.06c0-.1,0-.24,0-.38s0-.27,0-.41A18,18,0,0,1,4.42,6.44C8.09,2.2,12.49,0,17.5,0a15.19,15.19,0,0,1,8.11,2,25.85,25.85,0,0,1,3.81,2.72L31.06,6l1.66-1.37A26.68,26.68,0,0,1,36.52,2a15,15,0,0,1,8.1-2c5,0,9.42,2.2,13.08,6.44a18,18,0,0,1,4.42,11.83v.84h0c.08,1.93-.64,9.24-7.4,15.43l-.43.38A91.5,91.5,0,0,1,39.75,45.08,17.52,17.52,0,0,1,31.06,47.38ZM17.69,2.5h-.17c-4.26,0-8,1.9-11.21,5.57A15.54,15.54,0,0,0,2.5,18.27a2.91,2.91,0,0,0,0,.3v.19c0,.12,0,.23,0,.35s-.16,7.37,6.62,13.58l.4.36a92.18,92.18,0,0,0,14.1,9.86,15.09,15.09,0,0,0,14.89,0A90.29,90.29,0,0,0,52.61,33c.25-.22.39-.34.39-.35a19.29,19.29,0,0,0,6.62-13.26v-.86c0-.09,0-.2,0-.3A15.49,15.49,0,0,0,55.81,8.08C52.64,4.4,48.87,2.53,44.61,2.5a12.7,12.7,0,0,0-6.87,1.66,23.14,23.14,0,0,0-3.41,2.43L31.06,9.28,27.81,6.6a23.09,23.09,0,0,0-3.42-2.44A12.52,12.52,0,0,0,17.69,2.5Z" />
      </svg>
    );
  }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 59.63 44.88"
      height={props.height}
      width={props.width}
      className={props.className}
      fill={props.color}
    >
      <path d="M59.62,17.86c0-.15,0-.29,0-.44s0-.26,0-.4A16.72,16.72,0,0,0,55.51,6c-3.07-3.55-7.07-6-12.15-6a13.91,13.91,0,0,0-7.48,1.81,25.64,25.64,0,0,0-3.61,2.57l-2.46,2-2.45-2a25.64,25.64,0,0,0-3.61-2.57A13.93,13.93,0,0,0,16.26,0C11.19,0,7.19,2.45,4.12,6A16.73,16.73,0,0,0,0,17c0,.14,0,.28,0,.4s0,.29,0,.44-.31,7.78,7,14.5l.41.37a92,92,0,0,0,14.31,10,16.28,16.28,0,0,0,16.13,0,91.16,91.16,0,0,0,14.3-10l.42-.37C59.94,25.64,59.63,17.86,59.62,17.86Z" />
    </svg>
  );
};

export default Heart;
